@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-label {
    @apply uppercase;
    @apply font-semibold;
    @apply text-gray-500;
    font-size: 1.1em;
  }

  .btn-shadow {
    box-shadow: 0 0 5px #0000003b;
    &:active {
      box-shadow: inset 0 0 3px #0000003b;
    }
  }

  .error-input {
    border-color: #ff0000 !important;
    box-shadow: 0px 0px 0px 3px #ff000040 !important;
  }

  .table-heading {
    @apply font-medium text-[#888] uppercase;
  }

  .animate-toast-rest {
    transform: translateY(100%);
    opacity: 0;
    transition: 0.2s;
  }

  .animate-toast {
    transform: translateY(0);
    opacity: 1;
    display: block;
  }
}

@layer components {
  .btn-base {
    @apply rounded-full transition py-1 px-4 disabled:opacity-50;
  }

  .btn-success {
    @apply btn-base font-semibold text-success bg-success bg-opacity-20
    hover:text-success-dark hover:bg-opacity-25
    focus:text-success-dark focus:bg-opacity-30
    active:text-success-darker active:bg-opacity-[.35];
  }

  .btn-primary {
    @apply btn-base font-semibold text-primary bg-primary bg-opacity-20
    hover:text-primary-dark hover:bg-opacity-25
    focus:text-primary-dark focus:bg-opacity-30
    active:text-primary-darker active:bg-opacity-[.35];
  }

  .btn-danger {
    @apply btn-base font-semibold text-danger bg-danger bg-opacity-20
    hover:text-danger-dark hover:bg-opacity-25
    focus:text-danger-dark focus:bg-opacity-30
    active:text-danger-darker active:bg-opacity-[.35];
  }

  .btn-text {
    @apply btn-base text-gray-500
    hover:text-gray-800 hover:bg-gray-100
    focus:text-gray-800 focus:bg-gray-100
    active:text-gray-900 active:bg-gray-200;
  }

  .btn-link {
    @apply btn-base px-2 text-gray-500 
    hover:text-gray-800 focus:text-gray-800 active:text-gray-900 hover:underline focus:underline;
  }

  .btn-link-primary {
    @apply btn-link
    hover:text-primary focus:text-primary-dark active:text-primary-darker;
  }

  .btn-icon {
    @apply rounded-full transition p-2;
    line-height: 0;

    & i {
      @apply w-4;
      @apply h-4;
    }
  }

  .btn-icon-sm {
    @apply rounded-full transition p-1 leading-[0];
    & i {
      @apply w-3 h-3 leading-[0.875] text-sm;
    }
  }

  .btn-danger-icon {
    @apply btn-icon text-danger
    hover:text-danger-dark hover:bg-danger hover:bg-opacity-10
    focus:text-danger-dark focus:bg-danger focus:bg-opacity-[.125]
    active:text-danger-darker active:bg-opacity-[.15]
    disabled:text-gray-300 disabled:bg-transparent;
  }

  .btn-neutral-icon {
    @apply btn-icon text-gray-500  
    hover:text-gray-800 hover:bg-black hover:bg-opacity-5
    focus:text-gray-800 focus:bg-black focus:bg-opacity-[.075]
    active:text-gray-900 active:bg-opacity-10
    disabled:text-gray-300 disabled:bg-transparent;
  }

  .btn-neutral-icon-sm {
    @apply btn-icon-sm text-gray-500  
    hover:text-gray-800 hover:bg-black hover:bg-opacity-5
    focus:text-gray-800 focus:bg-black focus:bg-opacity-[.075]
    active:text-gray-900 active:bg-opacity-10
    disabled:text-gray-300 disabled:bg-transparent;


  }

  .dot {
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    border-radius: 50%;
    @apply border-[3px];
    vertical-align: -0.05em;
  }

  .dot-red {
    @apply dot border-red-500 hover:border-red-600;
  }
  .dot-red-filled {
    @apply dot-red bg-red-500 hover:bg-red-600;
  }
  .dot-yellow {
    @apply dot border-yellow-400 hover:border-yellow-500;
  }
  .dot-yellow-filled {
    @apply dot-yellow bg-yellow-400 hover:bg-yellow-500;
  }
  .dot-green {
    @apply dot border-green-500 hover:border-green-600;
  }
  .dot-green-filled {
    @apply dot-green bg-green-500 hover:bg-green-600;
  }

  .custom-radio {
    appearance: none;
    cursor: pointer;
    padding: 0;
    background: transparent;

    &:hover {
      transform: scale(1.15);
    }

    &:active {
      transform: scale(0.85);
    }
  }

  .radio-red {
    @apply custom-radio dot-red checked:dot-red-filled;
  }
  .radio-yellow {
    @apply custom-radio dot-yellow checked:dot-yellow-filled;
  }
  .radio-green {
    @apply custom-radio dot-green checked:dot-green-filled;
  }
}

@layer base {
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

  /* @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
        url('./public/fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
        url('./public/fonts/SFProDisplay-Ultralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display UltralightItalic'), local('SFProDisplay-UltralightItalic'),
        url('./public/fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'),
        url('./public/fonts/SFProDisplay-UltralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  } */

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Bold'), local('SFProText-Bold'), url('./public/fonts/SFProText-Bold.woff2') format('woff2'),
      url('./public/fonts/SFProText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Light'), local('SFProText-Light'),
      url('./public/fonts/SFProText-Light.woff2') format('woff2'),
      url('./public/fonts/SFProText-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Regular'), local('SFProText-Regular'),
      url('./public/fonts/SFProText-Regular.woff2') format('woff2'),
      url('./public/fonts/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Medium'), local('SFProText-Medium'),
      url('./public/fonts/SFProText-Medium.woff2') format('woff2'),
      url('./public/fonts/SFProText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text MediumItalic'), local('SFProText-MediumItalic'),
      url('./public/fonts/SFProText-MediumItalic.woff2') format('woff2'),
      url('./public/fonts/SFProText-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text BoldItalic'), local('SFProText-BoldItalic'),
      url('./public/fonts/SFProText-BoldItalic.woff2') format('woff2'),
      url('./public/fonts/SFProText-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  /* @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text HeavyItalic'), local('SFProText-HeavyItalic'),
        url('./public/fonts/SFProText-HeavyItalic.woff2') format('woff2'),
        url('./public/fonts/SFProText-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  } */

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text LightItalic'), local('SFProText-LightItalic'),
      url('./public/fonts/SFProText-LightItalic.woff2') format('woff2'),
      url('./public/fonts/SFProText-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }

  /* @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Heavy'), local('SFProText-Heavy'),
        url('./public/fonts/SFProText-Heavy.woff2') format('woff2'),
        url('./public/fonts/SFProText-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  } */

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Regular Italic'), local('SFProText-RegularItalic'),
      url('./public/fonts/SFProText-RegularItalic.woff2') format('woff2'),
      url('./public/fonts/SFProText-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  /* @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Black Italic'), local('SFProDisplay-BlackItalic'),
        url('./public/fonts/SFProDisplay-BlackItalic.woff2') format('woff2'),
        url('./public/fonts/SFProDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  } */

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
      url('./public/fonts/SFProDisplay-Bold.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text SemiboldItalic'), local('SFProText-SemiboldItalic'),
      url('./public/fonts/SFProText-SemiboldItalic.woff2') format('woff2'),
      url('./public/fonts/SFProText-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }
  /* 
  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
        url('./public/fonts/SFProDisplay-Heavy.woff2') format('woff2'),
        url('./public/fonts/SFProDisplay-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  } */

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
      url('./public/fonts/SFProDisplay-BoldItalic.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Text';
    src: local('SF Pro Text Semibold'), local('SFProText-Semibold'),
      url('./public/fonts/SFProText-Semibold.woff2') format('woff2'),
      url('./public/fonts/SFProText-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  /* @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
        url('./public/fonts/SFProDisplay-Black.woff2') format('woff2'),
        url('./public/fonts/SFProDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  } */

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display SemiboldItalic'), local('SFProDisplay-SemiboldItalic'),
      url('./public/fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Regular Italic'), local('SFProDisplay-RegularItalic'),
      url('./public/fonts/SFProDisplay-RegularItalic.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
      url('./public/fonts/SFProDisplay-MediumItalic.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
      url('./public/fonts/SFProDisplay-Medium.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
      url('./public/fonts/SFProDisplay-Semibold.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  /* @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
        url('./public/fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'),
        url('./public/fonts/SFProDisplay-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
  } */

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
      url('./public/fonts/SFProDisplay-Regular.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
      url('./public/fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
      url('./public/fonts/SFProDisplay-Light.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display ThinItalic'), local('SFProDisplay-ThinItalic'),
      url('./public/fonts/SFProDisplay-ThinItalic.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
      url('./public/fonts/SFProDisplay-Thin.woff2') format('woff2'),
      url('./public/fonts/SFProDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  html {
    font-family: 'SF Pro Text', Roboto, sans-serif;
    color: #333;

    /* background: #f0f0f0; */
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
    // background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    @apply bg-[#e8e8e8] hover:bg-gray-300 active:bg-gray-400 transition rounded-full p-2;
    border: 0.25rem solid transparent;
    background-clip: content-box;
  }

  ::selection {
    @apply bg-primary bg-opacity-20;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'SF Pro Display';
    font-weight: 500;
    margin-bottom: 0.5em;
  }
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.3em;
  }

  h4 {
    font-size: 1.15em;
    font-weight: 500;
  }

  input,
  textarea,
  select {
    $bg-color: #ededed;

    padding: 0.3em 0.6em;
    /* margin-top: 0.25em;
    margin-bottom: 1em; */

    background: $bg-color;
    border: solid 1px $bg-color;
    @apply rounded-xl;

    transition: 0.15s all;

    &:hover {
      border-color: #ddd;
    }

    &:focus {
      border-color: #2196f3;
      box-shadow: 0px 0px 0px 3px #2196f354;
    }
  }

  /* input[type="radio"]:before {
    content: ' ';
    width: .4em;
    height: .4em;
    background: red;
    border-radius: 100%;
    position: absolute;
    top: 0.1em;
    left: 0.1em;
  } */

  /* input[type="radio"] + label:before {
    content: ' ';
    display: inline-block;
    width: .8em;
    height: .8em;
    margin-right: 0.25em;
    background: #fff;
    border: solid 0.15em blue;
    border-radius: 50%;
    vertical-align: -0.05em;
  } */

  select {
    padding-top: 0.425em;
    padding-bottom: 0.4em;
  }

  input::placeholder {
    font-weight: normal;
  }

  input:focus,
  textarea:focus,
  *[contenteditable='true'],
  select {
    outline: none !important;
  }

  /* label {
    @apply text-label;
  } */

  button {
    padding: 0.3em 0.8em;
    @apply rounded-xl;
    @apply font-medium;
    outline: none !important;
  }

  th {
    font-weight: 500;
  }

  a {
    text-decoration: underline;
    @apply cursor-pointer;
    @apply text-gray-500;
    @apply hover:text-gray-800;
    @apply active:text-gray-900;
  }

  #__next {
    min-width: fit-content;
  }

  .shadow-card.min-w-fit .flex {
    flex-wrap: wrap;
  }

  * {
    user-select: none;
  }

  table * {
    user-select: text;
  }

  .page-margin {
    @apply px-6 pb-5 overflow-x-auto;
  }

  .container-std {
    @apply max-w-view mx-auto;
  }

  .container-full {
    max-width: calc(100vw - 1rem);
    @apply mx-auto;
  }

  .page-container-std {
    @apply container-std page-margin;
  }

  .page-container-full {
    @apply container-full page-margin;
  }


  .page-container-board {
    @apply grid w-screen fixed inset-0 max-h-screen;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr auto;
    grid-template-areas:
      'header sidebar'
      'main sidebar'
      'warning warning';
  }

  .sidebar-shadow {
    $shadow-size: 8px;
    box-shadow: (-$shadow-size) 0 $shadow-size (-$shadow-size) #00000020;
  }

  .undertitle-buttons {
    @apply flex items-center space-x-2 text-sm;

    .btn {
      @apply btn-text bg-transparent;

      .btn-label {
        @apply max-w-[0rem] overflow-hidden inline-block align-bottom whitespace-nowrap;
        transition-property: max-width, color, background-color;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;

        &::before {
          content: '';
          display: inline-block;
          width: 0.3rem;
        }
      }

      &.btn-danger {
        @apply hover:bg-red-100 hover:text-red-500 focus:bg-red-100 focus:text-red-500 active:bg-red-200 active:text-red-600;
        .btn-label {
          @apply text-red-500;

          
        }
      }

      &:hover {
        .btn-label {
          @apply max-w-[32rem];
          /* transition: all 0.3s ease-in-out, margin 0.15s ease-in-out 0.2s !important; */
        }
      }
    }
  }
}

.apexcharts-tooltip {
  border-radius: 0.5rem !important;
  border: none !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) !important;
}

// Sliders fix:
div.disabled {
  div.track.track-1 {
    background-color: #dfdfdf !important;
    left: 0 !important;
  }
}

.tree-custom-path {
  stroke: #8889 !important;
}

.rd3t-tree-container, .rd3t-node, .rd3t-leaf-node {
  cursor: default !important;
}

#treeWrapper > div > svg > g {
  /* transform: translate(50%, 40px) !important; */
}

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities';

@import 'bootstrap/scss/dropdown';
